<template>
    <v-container>
        <v-card-title>
            <span class="text-h4">
                Party Login
            </span>
        </v-card-title>
        <v-form>
            <v-text-field 
                v-model="partyName" 
                label="Party Name" 
                class="mt-2 ml-4 mr-4"
                outlined 
                required
            ></v-text-field>
            <v-text-field 
                v-model="partySecret" 
                label="Party Secret" 
                class="ml-4 mr-4"
                type="password"
                outlined 
                required
            ></v-text-field>
            <v-row>
                <v-col cols="3">
                    <v-btn 
                        v-on:click="login"
                        class="ml-4 mb-4" 
                        color="primary" 
                        x-large 
                        depressed
                    >
                        Login
                    </v-btn>
                </v-col>
                <v-col cols="9"></v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                partyName: '',
                partySecret: '',
                alert: false,
                alertMessage: '',
            };
        },
        methods: {
            // log the user in
            login() {
                // derive the email from the party name
                const email = this.partyName.toLowerCase().replace(/\s/g,'') + '@partyloottracker.com';

                // create a login object   
                const loginDetails = {
                    'email' : email,
                    'partySecret' : this.partySecret
                };

                // start a loading bar
                this.$emit('loading');

                // pass the login object to Vuex
                // if login fails, display an error message
                this.$store.dispatch('logIn', loginDetails)
                .then(() => { }, (error) => {
                    this.$emit('alert', error)
                });
            },
        },
    }
</script>
