<template>
    <v-container>
        <v-card-title>
            <span class="text-h4" style="word-break: break-word">
                Create a New Party
            </span>
        </v-card-title>
        <validation-observer 
            v-slot="{ invalid }"
            ref="observer"
        >
            <v-form>
                <validation-provider 
                    v-slot="{ errors }"
                    name="Party name"
                    rules="required|max:100" 
                >
                    <v-text-field 
                        v-model="partyName" 
                        label="Party Name" 
                        class="mt-2 ml-4 mr-4"
                        :error-messages="errors"
                        outlined 
                        required
                    ></v-text-field>
                </validation-provider>
                <validation-provider 
                    v-slot="{ errors }"
                    name="Party secret"
                    rules="required|min:6|max:100" 
                >
                    <v-text-field 
                        v-model="partySecret" 
                        label="Party Secret" 
                        class="ml-4 mr-4"
                        type="password"
                        :error-messages="errors"
                        outlined 
                        required
                    ></v-text-field>
                </validation-provider>
                <validation-provider 
                    v-slot="{ errors }"
                    name="Confirm party secret"
                    rules="required|min:6|max:100" 
                >
                    <v-text-field 
                        v-model="partySecretConfirm" 
                        label="Confirm Party Secret" 
                        class="ml-4 mr-4"
                        type="password"
                        :error-messages="errors"
                        outlined 
                        required
                    ></v-text-field>
                </validation-provider>
                <v-btn 
                    v-on:click="createParty"
                    class="ml-4 mb-4" 
                    color="primary" 
                    :disabled="invalid"
                    x-large 
                    depressed
                >
                    Create
                </v-btn>
            </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
    export default {
        name: 'CreateParty',
        data() {
            return {
                partyName: '',
                partySecret: '',
                partySecretConfirm: '',
                invalid: true,
                alert: false,
                alertMessage: '',
            };
        },
        methods: {
            // create a new party
            createParty() {
                // check that the passwords match 
                // if passwords don't match, display an error message
                if (this.partySecret === this.partySecretConfirm) {
                    // create an email from the party name
                    const email = this.partyName.toLowerCase().replace(/\s/g,'') + '@partyloottracker.com'

                    // create a party object   
                    const partyDetails = {
                        'partyName' : this.partyName,
                        'partySecret' : this.partySecret,
                        'partyEmail' : email,
                    }

                    // start a loading bar
                    this.$emit('loading');

                    // pass the party object to Vuex
                    // if create party fails, display an error message
                    this.$store.dispatch('createParty', partyDetails)
                    .then(() => { }, (error) => {
                        this.$emit('alert', error)
                    });
                } else {
                    this.$emit('alert', 'Secrets do not match.')
                }
            }
        },
    }
</script>
