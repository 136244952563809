<template>
  <v-container>
    <v-row>
      <v-col cols="0" xl="2" lg="2" md="2" sm="2"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="8">
        <v-img
          :aspect-ratio="7/4"
          src="../assets/logo.svg"
          width=400
          class="mx-auto"
        ></v-img>
        <v-card class="mt-8"> 
          <v-tabs v-model="tab">
            <v-tab>
              Login
            </v-tab>
            <v-tab>
              Create Party
            </v-tab>
            <v-tab>
              What's This
              <v-icon class="ml-2">
                mdi-help-circle
              </v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :transition="false">
              <Login 
                @loading="inProgress=true" 
                @alert="displayAlert" 
              />
            </v-tab-item>
            <v-tab-item :transition="false">
              <CreateParty 
                @loading="inProgress=true" 
                @alert="displayAlert" 
              />
            </v-tab-item>
            <v-tab-item :transition="false">
              <v-card>
                <v-card-text>
                  <span class="subtitle-1">
                    Bag of Loot is a loot tracker designed for Dungeons and Dragons parties. 
                    It is intended to assist a party in keeping an accurate inventory of the items and coins they've collected throughout a campaign.
                    <br>
                    --------
                    <br>
                    To create a party, sign up with a unique party name and a communal secret. 
                    We do not want to store any personal information, so we don't collect emails.
                    This means there is no way to reset your secret if you forget it, so back it up securely and regularly take a copy of your party's data using the JSON export tool on the "Settings" page.
                    <br>
                    --------
                    <br>
                    Once logged in, members can easily view and edit the party's loot and coins, keeping track of inventory over time.
                    <br>
                    --------
                    <br>
                    Currently, this tool is completely free. 
                    The current features will always be free; however, if there is interest more features may be developed for a paid version.
                  </span>
                </v-card-text>
              </v-card>
             </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-progress-linear
          v-if="inProgress"
          class="mt-4"
          color="primary"
          height="10"
          indeterminate
          rounded
        ></v-progress-linear>
        <v-alert
          :value="alert"
          type="error"
          class="mt-4"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
      <v-col cols="0" xl="2" lg="2" md="2" sm="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Login from './Login.vue'
  import CreateParty from './CreateParty.vue'

  export default {
    name: 'Auth',
    components: {
      Login,
      CreateParty
    },
    data() {
      return {
        tab: '',
        inProgress: false,
        alert: false,
        alertMessage: '',
      }
    },
    methods: {
      // display alert message for 10 seconds
      displayAlert(alertMessage) {
        this.inProgress = false;
        this.alert = true;
        this.alertMessage = alertMessage;
        
        setTimeout(() => { 
            this.alert=false;
        }, 10000);
      }
    }
  }
</script>
